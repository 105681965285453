import React, { useMemo } from 'react';
import moment from 'moment';
import { useListContext } from 'react-admin';

import { makeStyles } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { RUNNING_INVOICING_STATUS } from 'admin/assets/js/constants';
import { API_DATE_FORMAT } from 'core/assets/js/constants';
import { INVOICED_METHOD } from 'finance/assets/js/constants';

const useStyles = makeStyles({
  container: {
    alignSelf: 'center',

    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const ListAction = () => {
  const classes = useStyles();

  const { selectedIds, data, filterValues: { invoiceDate } } = useListContext();
  const allIds = useMemo(() => Object.keys(data || {}).map(id => parseInt(id, 10)), [data]);
  // assume 'all' mode if no rows selected
  const isAll = useMemo(() => !selectedIds || !selectedIds.length, [selectedIds]);
  // filter all ids, to only those ids valid for invoicing ( ie audits successful )
  // 'all' mode is not a true 'all' mode, it's an all rows that are valid for invoicing
  const allIdsValidForInvoicing = useMemo(() => {
    return Object
      .values(data || {})
      .filter(d => d.invoicingStatus === RUNNING_INVOICING_STATUS.PASSED_AUDIT)
      .map(d => parseInt(d.id, 10));
  }, [data]);

  // disable invoicing based on status of rows selected
  // when no rows selected, use all 'pending' rows, ie those rows that have passed audits
  // also, disable if nothing is actually available for invoicing ( ie id list is empty )
  const disableInvoicing = useMemo(() => {
    const dataSet = isAll ? Object.values(data).filter(d => allIdsValidForInvoicing.includes(d.id))
      : Object.values(data).filter(d => selectedIds.includes(d.id));
    return !dataSet.length || !dataSet
      .every(d => (d.invoicingStatus === RUNNING_INVOICING_STATUS.PASSED_AUDIT));
  }, [isAll, selectedIds, allIdsValidForInvoicing, data]);

  return (
    <div className={classes.container}>
      <BulkApiActionButton
        variant="contained"
        size="medium"
        successNotifMsg="Audits started"
        label={`Re-run audits for ${isAll ? 'all' : 'selected'} orgs`}
        title={`Re-run audits for ${isAll ? 'all' : 'selected'} organizations`}
        content={`Are you sure you want to re-run audits for ${isAll ? 'all' : 'selected'} organizations ?`}
        icon={null}
        ids={isAll ? allIds : selectedIds}
        resource="audits"
        action="run"
        payload={{ date: moment(invoiceDate).format(API_DATE_FORMAT) }}
      />

      <BulkApiActionButton
        variant="contained"
        size="medium"
        successNotifMsg="Invoicing started"
        disabled={disableInvoicing || moment().isBefore(invoiceDate)}
        label={`Generate ${isAll ? 'all' : 'selected'} pending invoices`}
        title={`Generate ${isAll ? 'all' : 'selected'} pending invoices`}
        content={`Are you sure you want to generate ${isAll ? 'all' : 'selected'} pending invoices ?`}
        icon={null}
        ids={isAll ? allIdsValidForInvoicing : selectedIds}
        resource="run_invoicing"
        action="generate"
        payload={{
          date: moment(invoiceDate).format(API_DATE_FORMAT),
          invoicedMethod: INVOICED_METHOD.TOOLING,
        }}
      />
    </div>
  );
};

export default ListAction;
