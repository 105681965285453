import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { AUDIT_LEVEL, AUDIT_MESSAGE_LABELS, AUDIT_MESSAGE_TITLES } from 'finance/assets/js/constants';
import AuditLevel from 'admin/assets/js/resources/fact_audit_log_groups/AuditLevel';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton';
import Money from 'finance/assets/js/lib/Money';


const useStyles = makeStyles({
  topDivider: {
    width: '100%',
    margin: '-4px 0 8px',
    padding: 0,
  },
  bottomDivider: {
    width: '100%',
    margin: '8px 0',
    padding: 0,
  },
  container: {
    padding: 16,
    minHeight: 400,
    backgroundColor: '#f5f5f5',
  },
});

const AuditLog = ({ log: { id, level, message, waivedBy, context } })  => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const onOpen = useCallback(() => setOpen(true), [setOpen]);
  const onClose = useCallback(() => setOpen(false), [setOpen]);

  return (
    <>
      <Button
        color="primary"
        variant="text"
        onClick={onOpen}
      >
        View Details
      </Button>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="md"
        fullWidth
      >
        <div className="d-flex justify-content-between">
          <DialogTitle>
            {AUDIT_MESSAGE_TITLES[message]}
            {' '}
            <AuditLevel level={level} />
          </DialogTitle>
          <IconButton
            className="mr-2"
            aria-label="close"
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <hr className={classes.topDivider} />
        <DialogContent>
          <div className={classes.container}>
            <p>{AUDIT_MESSAGE_LABELS[message]}</p>
            <p>
              {'This invoice is of type '}
              &apos;
              {context.invoiceType}
              &apos;
              {context.invoiceCurrency ? `, and it's amount appears to be ${new Money(context.invoiceAmount, context.invoiceCurrency).toString({ withSymbol: true, humanizeAmount: true })}` : ''}
              .
            </p>
            {context.ownerId && (
              <p>
                {`The owner of this invioce is user id ${context.ownerId}, bare in mind when this is a client invoice this will be the organization owner id.`}
              </p>
            )}
          </div>
        </DialogContent>
        <hr className={classes.bottomDivider} />
        <DialogActions className="mr-3">
          {!waivedBy && [AUDIT_LEVEL.CRITICAL, AUDIT_LEVEL.SEVERE].includes(level) && (
            <BulkApiActionButton
              successNotifMsg="Audit successfully waived"
              label="Accept &amp; Continue"
              ids={[id]}
              resource="audits"
              action="waive"
              confirm={false}
            />
          )}
          <Button
            color="primary"
            variant="text"
            onClick={onClose}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

AuditLog.propTypes = {
  log: PropTypes.object.isRequired,
};

export default AuditLog;
