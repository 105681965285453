import React, { useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  ListContextProvider, Datagrid, FunctionField, Link, useNotify, useUpdate, useListController,
  FormDataConsumer, useUnselectAll,
} from 'react-admin';
import { Typography } from '@material-ui/core';
import { Field, Form } from 'react-final-form';

import {
  getAllocationFormInputFieldName,
  parseFormValues,
  validateAllocationInputField,
} from 'admin/assets/js/resources/inbound_transfers/utils';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';
import InvoiceAmountField from 'admin/assets/js/resources/inbound_transfers/InvoiceAmountField.jsx';
import AllocationFormHeader from 'admin/assets/js/resources/inbound_transfers/AllocationFormHeader.jsx';
import { logger } from 'core/assets/js/lib/Logger';
import Money from 'finance/assets/js/lib/Money';
import { formatDate } from 'admin/assets/js/lib/utils';

const getInvAmount = inv => inv.total;


const InvoicesList = ({
  remoteId,
  organization,
  transferAmount,
  transferAmountCurrency,
  totalAllocatedAmount,
  fxMap,
  onAllocation,
}) => {
  const notify = useNotify();
  const [update, { loading }] = useUpdate();
  const context = useListController({
    resource: 'invoices',
    basePath: '/invoices',
    filter: { suggestedForTransfer: remoteId },
    perPage: 100,
  });
  const { data, selectedIds } = context;
  const unselectAll = useUnselectAll('invoices');

  // unselect any previously selected invoices when the
  // selected transfer changes ( remoteId )
  useEffect(() => {
    unselectAll();
  }, [unselectAll, remoteId]);

  const initialFormValues = useMemo(() => {
    const invoices = Object.values(data);
    const initialValues = {};
    invoices.forEach((inv) => {
      const fieldName = getAllocationFormInputFieldName(inv.id);
      initialValues[fieldName] = getInvAmount(inv);
      initialValues[`${fieldName}-currency`] = inv.currency;
    });

    return initialValues;
  }, [data]);

  const totalAmountChosen = useCallback(formData => (
    // note - use form data, rather than data, so we get the current value in the input box
    selectedIds.reduce((acc, val) => {
      const formCurrency = (formData[`inv-${val}-currency`] || 'gbp').toUpperCase();
      const exchangeRate = fxMap[formCurrency] || 1;
      const formAmount = new Money(parseFloat(formData[`inv-${val}`]) || 0, formCurrency);
      return acc
        .add(formAmount.convert(transferAmountCurrency, { reverseRate: exchangeRate }));
    }, new Money(0, transferAmountCurrency)).toString()
  ), [selectedIds, fxMap, transferAmountCurrency]);

  const handleGenerateClick = useCallback((values) => {
    const allocationRequest = parseFormValues(values, { transferId: remoteId, selectedIds });
    // don't update if we ended up with 'no valid' request
    if (allocationRequest) {
      update(
        'inbound_transfers', remoteId, { allocationRequest }, {}, {
          onSuccess: () => {
            notify('Payment allocated!', { type: 'success' });
            onAllocation();
          },
          onFailure: (error) => {
            logger.error(error);
            notify('Payment allocation failed!', { type: 'error' });
          },
        },
      );
    }
  }, [notify, update, remoteId, selectedIds, onAllocation]);

  // note - adding an empty form validator forces validation for each field to be run
  //        at the form level ( when selecting all rows for example ), which is what we need
  //        we can't run validation at the form level as we don't know which
  //        rows are selected
  return (
    <Form
      onSubmit={handleGenerateClick}
      initialValues={initialFormValues}
      validate={() => {}}
    >
      {({ handleSubmit, invalid }) => (
        <form onSubmit={handleSubmit}>
          <div>
            <AllocationFormHeader
              organization={organization}
              loading={loading}
              invalid={invalid}
              selectedIds={selectedIds}
              transferAmount={transferAmount}
              transferAmountCurrency={transferAmountCurrency}
              totalAllocatedAmount={totalAllocatedAmount}
              totalAmountChosen={totalAmountChosen}
            />
            <ListContextProvider
              value={context}
            >
              <FormDataConsumer>
                {({ formData }) => (
                  <Datagrid
                    hasBulkActions
                  >
                    <FunctionField
                      source="total"
                      label={<Typography variant="subtitle2" className="font-weight-bold">Amount to allocate</Typography>}
                      render={inv => (
                        <Field
                          name={getAllocationFormInputFieldName(inv.id)}
                          validate={val => (
                            validateAllocationInputField(
                              val,
                              getInvAmount(inv),
                              inv.currency,
                              totalAllocatedAmount,
                              totalAmountChosen(formData),
                              transferAmount,
                              transferAmountCurrency,
                            )
                          )}
                        >
                          {({ input, meta }) => (
                            <div
                              style={{
                                margin: '5px 0',
                              }}
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                }}
                              >
                                <span
                                  style={{ marginRight: '10px' }}
                                >
                                  {CURRENCY_SYMBOL[inv.currency]}
                                </span>
                                <input
                                  style={{
                                    padding: '10px',
                                    borderWidth: '0 0 1px 0',
                                    width: '150px',
                                    backgroundColor: '#ededed',
                                    borderRadius: '2px 2px 0',
                                  }}
                                  {...input}
                                  type="text"
                                />
                              </div>
                              {meta.error && meta.touched && (
                                <span
                                  style={{
                                    paddingLeft: '15px',
                                    color: 'tomato',
                                    display: 'inline-block',
                                  }}
                                >
                                  {meta.error}
                                </span>
                              )}
                            </div>
                          )}
                        </Field>
                      )
                      }
                    />
                    <FunctionField
                      label={(
                        <Typography variant="subtitle2" className="font-weight-bold">
                          Invoice
                        </Typography>
                      )}
                      render={inv => (
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                          <Link style={{ whiteSpace: 'nowrap' }} to={`/invoices/${inv.id}`}>{inv.uniqueNumber}</Link>
                          {formatDate(inv.createdAt)}
                        </div>
                      )}
                    />
                    <InvoiceAmountField
                      label={<Typography variant="subtitle2" className="font-weight-bold">Amount</Typography>}
                    />
                  </Datagrid>
                )}
              </FormDataConsumer>
            </ListContextProvider>
          </div>
        </form>
      )}
    </Form>
  );
};

InvoicesList.propTypes = {
  remoteId: PropTypes.oneOfType([
    PropTypes.number, PropTypes.string,
  ]),
  organization: PropTypes.object,
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
  fxMap: PropTypes.object,
  onAllocation: PropTypes.func,
};

InvoicesList.defaultProps = {
  remoteId: null,
  organization: {},
  transferAmount: null,
  transferAmountCurrency: null,
  totalAllocatedAmount: '0.00',
  fxMap: {},
  onAllocation: () => {},
};

export default InvoicesList;
