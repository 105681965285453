import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Icon, makeStyles } from '@material-ui/core';

import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { CANCELLATION_REASON, TRANSACTION_STATUS } from 'finance/assets/js/constants';
import ReplayIcon from '@material-ui/icons/Replay';

const useStyles = makeStyles({
  container: {
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const TransactionViewActions = ({ id, status }) => {
  const [reason, setReason] = useState(Object.keys(CANCELLATION_REASON)[0]);
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <BulkApiActionButton
        variant="contained"
        size="medium"
        label="Recreate"
        title="Recreate transaction"
        content="Are you sure you want to recreate a transaction for this invoice?  If this invoice is deferred, then this will recreate a single transaction for all invoices in the group."
        ids={[id]}
        icon={<ReplayIcon />}
        resource="transactions"
        action="recreate_transactions"
        disabled={[TRANSACTION_STATUS.SUCCEEDED].includes(status)}
      />
      <BulkApiActionButton
        variant="contained"
        size="medium"
        title={`Sync transfer #${id}`}
        content={`
            Transfer ${id} will be synced. Proceed with caution.
          `}
        confirm
        icon={<Icon>refresh</Icon>}
        ids={[id]}
        label="Sync"
        resource="dim_transferwise_transfers"
        action="sync-trw-transfers"
        cancelLabel="Close"
      />
      <BulkApiActionButton
        variant="contained"
        size="medium"
        title={`Cancel transaction #${id}`}
        content={(
          <>
            <p>{`Transaction ${id} will be cancelled. Proceed with caution.`}</p>
            <select
              name="cancel_reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
            >
              {Object.entries(CANCELLATION_REASON)
                .map(([k, v]) => (<option id={k} value={k}>{v}</option>))}
            </select>
          </>
        )}
        confirm
        icon={<Icon>cancel</Icon>}
        ids={[id]}
        payload={{ reason: CANCELLATION_REASON[reason] }}
        label="Cancel"
        resource="transactions"
        action="cancel-transactions"
        cancelLabel="Close"
        disabled={[
          TRANSACTION_STATUS.SUCCEEDED,
          TRANSACTION_STATUS.FAILED,
          TRANSACTION_STATUS.CANCELLED,
          TRANSACTION_STATUS.REFUNDED,
          TRANSACTION_STATUS.REMOVED,
        ].includes(status)}
      />
    </div>
  );
};

TransactionViewActions.propTypes = {
  id: PropTypes.string.isRequired,
  status: PropTypes.number.isRequired,
};

export default TransactionViewActions;
