import React from 'react';
import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  CardHeader,
  Typography,
} from '@material-ui/core';
import { Link, useGetList } from 'react-admin';
import { groupBy, maxBy } from 'lodash';

import TransactionDetailsField
  from 'admin/assets/js/resources/transactions/TransactionDetailsField.jsx';

const CashoutRequestViewTransactions = ({ transactionIds }) => {
  const { loading, data: transactions, ids } = useGetList(
    'transactions',
    { page: 1, perPage: 9 },
    { field: 'id', order: 'DESC' },
    { byIds: transactionIds },
  );
  if (ids.length === 0) {
    return null;
  }

  const transactionGroupedByDescriptor = groupBy(transactions, 'descriptor');
  return (
    <Card variant="outlined">
      <CardHeader
        style={{ paddingBottom: 0, marginBottom: 0 }}
        title={(
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 600 }}
          >
            Transaction details
          </Typography>
        )}
      />
      <CardContent>
        {ids.length === 0 && !loading && 'No transactions found'}
        {ids.length > 0 && (
          Object.keys(transactionGroupedByDescriptor)
            .map((key, index) => {
              const latestTransaction = maxBy((transactionGroupedByDescriptor[key]), 'id');
              return (
                <div key={key}>
                  <div className="mb-4">
                    <div className="mb-1 font-weight-bold">Related transactions</div>
                    {transactionGroupedByDescriptor[key]
                      .filter(tr => latestTransaction.id !== tr.id)
                      .map(tr => (
                        <Link to={`/transactions/${tr.id}/details`} className="mr-2">
                          {`#${tr.id}`}
                        </Link>
                      ))
                    }
                  </div>
                  <div className="mb-1 font-weight-bold">Latest transaction</div>
                  <TransactionDetailsField
                    record={latestTransaction}
                    isDeferred
                  />
                  {ids.length > 1 && index !== ids.length && <hr /> }
                </div>
              );
            }))
        }
      </CardContent>
    </Card>
  );
};

CashoutRequestViewTransactions.propTypes = {
  transactionIds: PropTypes.array,
};

CashoutRequestViewTransactions.defaultProps = {
  transactionIds: [],
};

export default CashoutRequestViewTransactions;
