import React from 'react';
import PropTypes from 'prop-types';
import { Box, LinearProgress } from '@material-ui/core';
import pluralize from 'pluralize';

import Money from 'finance/assets/js/lib/Money';
import NumberTpl from 'core/assets/js/components/NumberTpl.jsx';
import { CURRENCY_SYMBOL } from 'core/assets/js/constants';

const AllocationAggregatedInfo = ({
  selectedIds,
  transferAmount,
  transferAmountCurrency,
  amountToAllocate,
  totalAllocatedAmount,
}) => {
  const selectedIdsCount = selectedIds.length;
  const allocatedAmount = new Money(amountToAllocate, transferAmountCurrency).add(
    totalAllocatedAmount || 0,
  );
  const allocatedPercent = (
    allocatedAmount.toNumber() / (new Money(transferAmount, transferAmountCurrency).toNumber())
  ) * 100;
  const availableAllocationAmount = new Money(transferAmount, transferAmountCurrency)
    .sub(totalAllocatedAmount)
    .sub(amountToAllocate)
    .toString();

  return (
    <div className="allocation-aggregated-info">
      <div>
        Allocation:
        {' '}
        <strong>{selectedIdsCount}</strong>
        {` chosen ${pluralize('invoice', selectedIdsCount)}`}
      </div>
      <div>
        <div>
          <strong>
            <NumberTpl
              value={availableAllocationAmount}
              prefix={CURRENCY_SYMBOL[transferAmountCurrency]}
            />
          </strong>
          {' '}
          available for allocation
        </div>
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={allocatedPercent} />
        </Box>
      </div>
    </div>
  );
};

AllocationAggregatedInfo.propTypes = {
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  amountToAllocate: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
};

AllocationAggregatedInfo.defaultProps = {
  selectedIds: [],
  transferAmount: '0.00',
  transferAmountCurrency: null,
  amountToAllocate: '0.00',
  totalAllocatedAmount: '0.00',
};

export default AllocationAggregatedInfo;
