import Money from 'finance/assets/js/lib/Money';
import TransferAllocationRequest from 'services/assets/js/lib/TransferAllocationRequest';
import { set } from 'lodash';
import { assertAllKeysPresent } from 'core/assets/js/lib/utils';
import { ALLOCATION_TRANSFER_TOLERANCE } from 'finance/assets/js/constants';

const getAllocationFormInputFieldName = invoiceId => `inv-${invoiceId}`;

const validateAllocationInputField = (
  formValue, invoiceTotal, invoiceCurrency,
  totalAllocatedAmount, totalAmountChosen, transferAmount, transferAmountCurrency,
) => {
  if (!formValue) {
    return false;
  }
  assertAllKeysPresent({
    formValue, invoiceTotal, invoiceCurrency,
    totalAllocatedAmount, totalAmountChosen, transferAmount, transferAmountCurrency,
  });

  const invoiceMoney = new Money(invoiceTotal, invoiceCurrency);
  if (invoiceMoney.cmp(formValue) < 0) {
    return 'Amount should not exceed invoice amount';
  }

  const transferUsageAmount = new Money(
    totalAllocatedAmount,
    transferAmountCurrency,
  ).add(totalAmountChosen);
  const maxTransferUsageAmount = new Money(transferAmount, transferAmountCurrency)
    .mul(ALLOCATION_TRANSFER_TOLERANCE);
  if (transferUsageAmount.gte(maxTransferUsageAmount)) {
    return `Total allocation of transfer can not exceed ${ALLOCATION_TRANSFER_TOLERANCE * 100}% or ${maxTransferUsageAmount.toString()}`;
  }

  return false;
};

const parseFormValues = (values, { transferId, selectedIds }) => {
  const perId = Object.keys(values).reduce((acc, curr) => {
    // remove prefix from key
    const key = curr.replace('inv-', '');
    if (key.endsWith('-currency')) {
      const id = parseInt(key.replace('-currency', ''), 10);
      if (selectedIds.includes(id)) {
        set(acc, `${id}.currency`, values[curr]);
      }
    } else {
      const id = parseInt(key, 10);
      if (selectedIds.includes(id)) {
        set(acc, `${id}.amount`, values[curr]);
      }
    }
    return acc;
  }, {});

  // prune any 'bad' form entries
  const sanitizedPerId = Object
    .keys(perId)
    .reduce((mapping, key) => {
      // we need an amount ( positive, non 0 ) and a currency ( non empty string )
      const parsedAmount = parseFloat(perId[key].amount || 0);
      if (parsedAmount > 0 && perId[key].currency) {
        // eslint-disable-next-line no-param-reassign
        mapping[key] = perId[key];
      }
      return mapping;
    }, {});
  // bail if nothing left to submit
  if (!Object.keys(sanitizedPerId).length) {
    return null;
  }

  const request = new TransferAllocationRequest({
    transferId,
    allocationPerInvoiceId: sanitizedPerId,
  });
  return request.serialize();
};

export {
  getAllocationFormInputFieldName,
  parseFormValues,
  validateAllocationInputField,
};
