import React, { useMemo } from 'react';
import moment from 'moment';
import {
  Datagrid,
  FunctionField,
  Link,
  List,
} from 'react-admin';
import { makeStyles } from '@material-ui/core';

import { API_DATE_FORMAT, DATETIME_FORMAT_DEFAULT } from 'core/assets/js/constants';
import { RUNNING_INVOICING_INVOICE_TYPE_LABEL, RUNNING_INVOICING_STATUS } from 'admin/assets/js/constants';
import { formatDate } from 'admin/assets/js/lib/utils';
import ListFilter from 'admin/assets/js/resources/run_invoicing/filters.jsx';
import ListAction from 'admin/assets/js/resources/run_invoicing/actions.jsx';
import InvoicingStatus from 'admin/assets/js/resources/run_invoicing/InvoicingStatus';
import BulkApiActionButton from 'admin/assets/js/components/buttons/BulkApiActionButton.jsx';
import { INVOICED_METHOD, INVOICED_METHOD_LABEL } from 'finance/assets/js/constants';


const useStyles = makeStyles({
  buttonContainer: {
    '& :not(:last-child)': {
      marginRight: '10px',
    },
  },
});

const RunInvoicingList = (props) => {
  const classes = useStyles();
  const today = useMemo(() => formatDate(new Date().toISOString(), API_DATE_FORMAT), []);
  return (
    <>
      <h2 className="mb-2">Organizations requiring invoice generation</h2>
      <List
        {...props}
        actions={<ListAction />}
        bulkActionButtons={<ListAction />}
        exporter={false}
        filterDefaultValues={{ invoiceDate: today }}
        filters={<ListFilter />}
        sort={{ field: 'organizationName', order: 'ASC' }}
      >
        <Datagrid
          hover={false}
        >
          <FunctionField
            label="Organization"
            sortBy="organizationName"
            render={({ organizationName, organizationId }) => (
              <Link to={`/organizations/${organizationId}/show`}>
                {organizationName}
              </Link>
            )}
          />
          <FunctionField
            label="Invoice date"
            sortBy="invoiceDate"
            render={({ invoiceDate }) => formatDate(new Date(invoiceDate), API_DATE_FORMAT)}
          />
          <FunctionField
            sortBy="invoiceType"
            label="Invoice type"
            render={({ invoiceType }) => RUNNING_INVOICING_INVOICE_TYPE_LABEL[invoiceType]}
          />
          <FunctionField
            label="Status"
            sortBy="invoicingStatus"
            render={({ invoicingStatus }) => (
              <InvoicingStatus status={invoicingStatus} />
            )}
          />
          <FunctionField
            label="Performed by"
            sortBy="performedByName"
            render={({ performedByName, performedById }) => (performedById ? (
              <Link to={`/full_users/${performedById}/show`}>
                {performedByName}
              </Link>
            ) : '-')}
          />
          <FunctionField
            label="Generated at"
            sortBy="generatedAt"
            render={({ generatedAt }) => (generatedAt ? formatDate(new Date(generatedAt), DATETIME_FORMAT_DEFAULT) : '-')}
          />
          <FunctionField
            label="Generated with"
            sortBy="invoicedMethod"
            render={({ invoicedMethod }) => (invoicedMethod ? INVOICED_METHOD_LABEL[invoicedMethod] : '-')}
          />
          <FunctionField
            label=""
            sortable={false}
            render={({ organizationId, organizationName, invoiceDate, invoicingStatus }) => (
              <div className={classes.buttonContainer}>
                <BulkApiActionButton
                  variant="outlined"
                  size="small"
                  successNotifMsg="Invoicing started"
                  label="Generate"
                  title={`Run invoicing for ${organizationName}`}
                  content={`Are you sure you want to run invoicing for ${organizationName} ?`}
                  icon={null}
                  ids={[organizationId]}
                  resource="run_invoicing"
                  action="generate"
                  payload={{
                    date: moment(invoiceDate).format(API_DATE_FORMAT),
                    invoicedMethod: INVOICED_METHOD.TOOLING,
                  }}
                  disabled={invoicingStatus !== RUNNING_INVOICING_STATUS.PASSED_AUDIT
                    || moment().isBefore(invoiceDate)}
                />
                <Link to={`/fact_audit_log_groups?filter={"orgId"%3A${organizationId}%2C"billingProcessDate"%3A"${moment(invoiceDate).subtract(1, 'day').format(API_DATE_FORMAT)}"}`}>
                  View
                </Link>
              </div>
            )}
          />
        </Datagrid>
      </List>
    </>
  );
};

export default RunInvoicingList;
