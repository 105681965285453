import React from 'react';
import { useFormState } from 'react-final-form';
import { Link } from 'react-admin';
import { makeStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import ReconciliationBulkActions
  from 'admin/assets/js/resources/inbound_transfers/ReconcilliationBulkActions.jsx';

const useStyles = makeStyles(() => ({
  overviewTransferWrapper: {
    position: 'sticky',
    top: '120px',
    backgroundColor: '#fff',
    zIndex: '95',
    padding: '20px 0',
  },
}));

const AllocationFormHeader = ({
  organization,
  selectedIds,
  transferAmount,
  transferAmountCurrency,
  loading,
  invalid,
  totalAllocatedAmount,
  totalAmountChosen,
}) => {
  const classes = useStyles();
  const { values: formData } = useFormState();
  return (
    <div className={classes.overviewTransferWrapper}>
      <>
        Displaying unpaid invoices for
        {' '}
        <Link
          to={`/organizations/${organization.id}/show`}
        >
          {organization.name}
        </Link>
        :
      </>
      <ReconciliationBulkActions
        selectedIds={selectedIds}
        transferAmount={transferAmount}
        transferAmountCurrency={transferAmountCurrency}
        disabled={loading || invalid}
        totalAllocatedAmount={totalAllocatedAmount}
        amountToAllocate={totalAmountChosen(formData)}
      />
    </div>
  );
};

AllocationFormHeader.propTypes = {
  organization: PropTypes.object,
  selectedIds: PropTypes.arrayOf(PropTypes.number),
  transferAmount: PropTypes.string,
  transferAmountCurrency: PropTypes.string,
  totalAllocatedAmount: PropTypes.string,
  loading: PropTypes.bool,
  invalid: PropTypes.bool,
  totalAmountChosen: PropTypes.func.isRequired,
};
AllocationFormHeader.defaultProps = {
  organization: {},
  selectedIds: [],
  transferAmount: null,
  transferAmountCurrency: null,
  totalAllocatedAmount: '0.00',
  invalid: false,
  loading: false,
};

export default AllocationFormHeader;
