import React from 'react';
import PropTypes from 'prop-types';
import { Info, Warning, Report, CheckCircle } from '@material-ui/icons';

import { BANNER_TYPE, COLORS } from 'admin/assets/js/constants';

const BANNER_COLORS = {
  [BANNER_TYPE.INFO]: COLORS.INFO,
  [BANNER_TYPE.WARNING]: COLORS.WARNING,
  [BANNER_TYPE.DANGER]: COLORS.DANGER,
  [BANNER_TYPE.SUCCESS]: COLORS.SUCCESS,
};

const getBannerIcon = (type) => {
  switch (type) {
    case BANNER_TYPE.WARNING:
      return <Warning />;
    case BANNER_TYPE.DANGER:
      return <Report />;
    case BANNER_TYPE.SUCCESS:
      return <CheckCircle />;
    case BANNER_TYPE.INFO:
    default:
      return <Info />;
  }
};

const Banner = ({ content, type }) => {
  const icon = getBannerIcon(type);
  return (
    <div
      className="text-white w-100 p-3 mb-4 rounded-sm d-flex"
      style={{ backgroundColor: BANNER_COLORS[type] }}
    >
      <span className="mr-2">{icon}</span>
      <span className="d-flex align-items-center">
        <span>
          {content}
        </span>
      </span>
    </div>
  );
};

Banner.propTypes = {
  type: PropTypes.string.isRequired,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default Banner;
